import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
import { Theatre } from "../../components/ui/icons/CategoryIcons/Theatre";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    //Delhi
    //Bengaluru
    //Pune
    //Hyderabad
    {
        title: "A sitar show!",
        subtitle: "For Mental Health!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1740378723/usj0qhsirkppryzvny4d.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "sitar-for-mental-health-by-rishab-rikhiram-sharma-hyderabad-may4-2025"
        }
    },
    {
        title: "TGIR",
        subtitle: "The Great Inflate Run 2025",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1741010156/dytjp2jcykug5adqmesl.png",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "the-great-inflate-run-2025-hyderabad-indias-first-inflatable-ocr-adventure-apr20-2025"
        }
    },
    {
        title: "Rockstar DSP",
        subtitle: "In Vizag this April!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1740380007/buemez01lmpdlpm7dbo0.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-vizag-feb22-2025"
        }
    },
    //Chennai
    //Kolkata
    //Jaipur
    {
        title: "A sitar show!",
        subtitle: "For Mental Health!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1740378723/usj0qhsirkppryzvny4d.jpg",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "sitar-for-mental-health-by-rishab-rikhiram-sharma-jaipur-apr27-2025"
        }
    },
    {
        title: "OG Rap King!",
        subtitle: "Yo Yo Honey Singh",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270411/ixidrpmrtsppinf9b5qk.jpg",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-jaipur-march29-2025"
        }
    },
    {
        title: "Tulum Vibes",
        subtitle: "At ZAMNA India",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739086468/c8owkrdtpobz2qsbvyka.png",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "zamna-india--gurugram"
        }
    },
    //Indore
    //Coimbatore
    //Ahmedabad
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1738343019/tlh2l67uxtv6yyjptryj.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1738342881/u4m4sjfdgjtbh5ix3ti7.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "zamna-india--gurugram"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740381586/cmd81dbbvqwrwvas5wjh.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740381635/j9zyfv5ppjvlhyw03cc6.jpg",
        includeCities: ["chandigarh"],
        link: {
            resource_type: "event",
            slug: "sitar-for-mental-health-by-rishab-rikhiram-sharma-chandigarh-may2-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740381708/lag5mhnrgveryh3s7agg.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740381749/hbib7m8fjn8utfrccdwx.jpg",
        includeCities: ["jaipur"],
        link: {
            resource_type: "event",
            slug: "sitar-for-mental-health-by-rishab-rikhiram-sharma-jaipur-apr27-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740382087/vzitftz3mnsnlbmpjap0.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740382188/jomlwnrbsqndcrkwo76i.jpg",
        includeCities: ["vizag", "visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-vizag-feb22-2025"
        }
    },
];
export const showcase = [
    //Type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740379141/eskol7ssxxlskurwgcie.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740379085/ra8rxusdqldbxxx7ajym.jpg",
        categoryIcon: <Music />,
        heading: "Sitar for Mental Health",
        subheading: "Experience the healing power of music with Rishab Rikhiram Sharma.",
        caption: "Immerse yourself in the magic of Rishab Rikhiram Sharma, an acclaimed sitarist, music producer & the last disciple of Pt. Ravi Shankar.",
        cta: "Read more",
        includeCities: ["pune", "hyderabad", "indore"],
        tag: "rishab-rikhiram-sharma",
        cardListType: "schedule-card",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "go",
            slug: "rishab-rikhiram-sharma"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/wx64dwheikrd4pphafj5.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/sbsum04y34h42ger3i4z.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh LIVE Chennai!",
        subheading: "Experience India's most beloved voice live!",
        caption: "Get ready to witness Arijit Singh perform timeless hits like 'Tum Hi Ho,' 'Kesariya,' and 'Channa Mereya' in an unforgettable live experience!",
        cta: "Buy Now",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-india-tour-chennai-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/a2f302q5jdphvsbgktsi.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/td7dl6kycur5at31bt1z.jpg",
        categoryIcon: <Music />,
        heading: "Yo Yo Honey Singh LIVE!",
        subheading: "Kolkata, are you ready? The OG hitmaker is back for a mega concert!",
        caption: "From Brown Rang to Lungi Dance, vibe to non-stop hits as Yo Yo Honey Singh takes over Kolkata!  Gather your crew & get your tickets now!",
        cta: "Buy Now",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-kolkata-april5-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740379141/eskol7ssxxlskurwgcie.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1740379085/ra8rxusdqldbxxx7ajym.jpg",
        categoryIcon: <Theatre />,
        heading: "Sitar for Mental Health",
        subheading: "Experience the healing power of music with Rishab Rikhiram Sharma.",
        caption: "Immerse yourself in the magic of Rishab Rikhiram Sharma, an acclaimed sitarist, music producer & the last disciple of Pt. Ravi Shankar.",
        cta: "Buy Now",
        includeCities: ["jaipur"],
        link: {
            resource_type: "event",
            slug: "sitar-for-mental-health-by-rishab-rikhiram-sharma-jaipur-apr27-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271780/yiljlkdwj7kfir1lmcjv.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271781/tlpa1m91vgnlp2rgiok6.jpg",
        categoryIcon: <Music />,
        heading: "DSP Live in Vizag!",
        subheading: "The musical powerhouse is bringing his high-energy hits to Vizag!",
        caption: "Rockstar Devi Sri Prasad is set to light up Vizag with his electrifying hits! Get your tickets now.",
        cta: "Buy Now",
        includeCities: ["vizag", "visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-vizag-feb22-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
];
