/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import ActionButton from "./ActionButton";
import Image from "./Image";
import { imageCacheLink, generateItemUrl } from "../helpers/linkGenerators";
import classNames from "classnames";
import { cardImage, eventCard } from "../styles/cardStyles";
import { sanitizeHtml } from "../helpers/misc/sanitizeHtml";
const formatPrice = (price) => {
    try {
        return new Intl.NumberFormat("en-IN").format(price);
    }
    catch (e) {
        // If there is no internationalization support just show the price as it is
    }
    return price;
};
const ItemCard = (props) => {
    return (<div css={css(props.metaClassName === "list-card" && eventCard)} className={classNames("item-card", props.metaClassName)}>
			<div css={css(cardImage)} className="item-card-image">
				<Image src={imageCacheLink(props.cardData.get("horizontal_cover_image"))} alt={props.cardData.get("name")}/>
				<h3 className="item-card-name">{props.cardData.get("name")}</h3>
				<span className={"item-card-pricing"}>{`₹${formatPrice(props.cardData.get("price"))}`}</span>
				<ActionButton text="BUY" href={generateItemUrl(props.cardData.get("event_slug"), props.cardData.get("show_id"), props.cardData.get("item_group_name"))} disabled={false} htmlCaption={""} hasItemEventActionModifier={true}/>
			</div>
			<div className="item-card-details">
				<p dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.cardData.get("description")) }}/>
			</div>
		</div>);
};
ItemCard.defaultProps = {
    metaClassName: ""
};
export default ItemCard;
